$eunomia-dark: #4f3d88;
$eunomia-dark-alt: #564b93;
$eunomia-light: #2196f3;
$eunomia-light-alt:#4ec5e8;
$eunomia-label: #ccc;
$eunomia-primary: $eunomia-dark;
$eunomia-secondary: $eunomia-light;
$eunomia-neutral: #fff;
$eunomia-pure-neutral: #fff;
$eunomia-positive: #3d85c6;
$eunomia-positive-alt: #3d85c6;
$eunomia-negative: #f6b26b;
$eunomia-negative-alt: #f6b26b;
$eunomia-action-bar-wrapper: transparent;
$eunomia-wrapper: #313543;
$eunomia-indicators-text: #9baec8;
$eunomia-cascade-action-bar-wrapper: transparent;
$motd: $lighter-text-color;
$nudge-border:  #ffffff4a;
$cascade-post-text: #d9e1e8;
$logo_path: "/eunomia/static/logo_dark.png";
